// @flow
import * as React from 'react';
import classnames from 'classnames';

type Props = {
  date: Date,
  className?: string,
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const PostDate = ({ date, className, ...props }: Props) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate() + 1; // Because zero-indexed
  return (
    <span title={date.toISOString()} className={classnames(className)} {...props}>
      {months[month]} {day}, {year}
    </span>
  );
};

export default PostDate;
