// @flow
import * as React from 'react';
import DefaultLayout from '../layouts/default';
import BlockContent from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import Container from '../components/grid/container';
import BlogPost from '../partials/blog-post';
import { getFluidGatsbyImage } from 'gatsby-source-sanity/lib';
import Image from '../components/image';

type Props = {|
  data: {
    sanityBlogPost: {
      title: string,
      created: string,
      promo: ?{
        asset: {
          url: string,
        }
      },
      slug: string,
      _rawContent: any,
    },
    site: {
      siteMetadata: {
        sanityConfig: {
          dataset: string,
          projectId: string,
        },
      },
    },
    markdownRemark: {
      html: string,
      excerpt: string,
      timeToRead: string,
      frontmatter: {
        date: string,
        title: string,
        feature?: {
          childImageSharp: {
            fixed: {
              src: string,
            },
          },
        },
      },
    },
  },
|};

const BlogPostTemplate = ({ data: { sanityBlogPost, markdownRemark, site } }: Props) => {
  if (sanityBlogPost) {
    const { sanityConfig } = site.siteMetadata;
    const serializers = {
      types: {
        blockContentImage: props => {
          const src = getFluidGatsbyImage(props.node.asset._id, { maxWidth: 800 }, sanityConfig);
          return (
            <figure>
              <Image src={src} alt={props.node.caption}/>
              <figcaption>{props.node.caption}</figcaption>
            </figure>
          );
        },
      },
    };

    const feature = sanityBlogPost.promo ? sanityBlogPost.promo.asset.url : undefined;

    return (
      <DefaultLayout slim>
        <Container withColumn>
          <BlogPost title={sanityBlogPost.title}
                    date={new Date(sanityBlogPost.created)}
                    excerpt={'n/a'}
                    timeToRead={''}
                    featureImgSrc={feature}>
            <BlockContent
              blocks={sanityBlogPost._rawContent}
              serializers={serializers}
            />
          </BlogPost>
        </Container>
      </DefaultLayout>
    );
  } else {
    const { html, frontmatter, excerpt, timeToRead } = markdownRemark;
    const feature = frontmatter.feature ? frontmatter.feature.childImageSharp.fixed.src : undefined;
    return (
      <DefaultLayout slim>
        <Container withColumn>
          <BlogPost title={frontmatter.title}
                    date={new Date(frontmatter.date)}
                    excerpt={excerpt}
                    timeToRead={timeToRead}
                    featureImgSrc={feature}>
            <div dangerouslySetInnerHTML={{ __html: html }}/>
          </BlogPost>
        </Container>
      </DefaultLayout>
    );
  }
};

export default BlogPostTemplate;

export const pageQuery = graphql`
    query($slug: String!) {
        sanityBlogPost(slug: {eq: $slug}) {
            title
            slug
            created
            promo {
                asset {
                    url
                }
            }
            _rawContent(resolveReferences: { maxDepth: 5 })
        }
        site {
            siteMetadata {
                sanityConfig {
                    dataset
                    projectId
                }
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            excerpt
            timeToRead
            html
            frontmatter {
                date
                title
                feature {
                    childImageSharp {
                        fixed(width: 1500) {
                            src
                        }
                    }
                }
            }
        }
    }
`;
