// @flow
import * as React from 'react';
import Helmet from 'react-helmet';

type Props = {
  siteTitle: string,
  children?: React.Node,
};

const Title = ({ children, siteTitle }: Props) => (
  <Helmet>
    {children
      ? <title>{children} | {siteTitle}</title>
      : <title>{siteTitle}</title>
    }
  </Helmet>
);

export default Title;
