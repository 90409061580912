// @flow
import * as React from 'react';
import classnames from 'classnames';
import Img from 'gatsby-image';
import './image.less';

type Props = {
  src: string | Object,
  className?: string,
  rounded?: boolean,
  alt: string,
  keepSmall?: boolean,
};

const Image = ({ className, keepSmall, src, alt, rounded, ...props }: Props) => {
  if (typeof src === 'string') {
    return (
      <img
        src={src}
        alt={alt}
        className={classnames(className, 'image', {
          'image--rounded': rounded,
          'image--keep-small': keepSmall,
        })}
        {...props}
      />
    );
  } else if (src.width) {
    return (
      <Img
        fixed={src}
        alt={alt}
        className={classnames(className, 'image', {
          'image--rounded': rounded,
          'image--keep-small': keepSmall,
        })}
        {...props}
      />
    );
  } else {
    return (
      <Img
        fluid={src}
        alt={alt}
        className={classnames(className, 'image', {
          'image--rounded': rounded,
          'image--keep-small': keepSmall,
        })}
        {...props}
      />
    );
  }
};

export default Image;
