// @flow

export function trackEvent(category: string, action?: string, label?: string) {
  if (!window.ga) {
    console.log('[track]', category, action, label);
    return;
  }

  if (action && label) {
    window.ga('send', 'event', category, action, label);
  } else if (action) {
    window.ga('send', 'event', category, action);
  } else {
    window.ga('send', 'event', category);
  }
}
