// @flow
import * as React from 'react';
import classnames from 'classnames';
import './row.less';

type Props = {
  className?: string,
  vCenter?: boolean,
  spaced?: boolean | number,
  children: React.Node,
};

const Row = ({ children, className, spaced, vCenter, ...props }: Props) => {
  const classes = {
    'row': true,
    'row--vcenter': vCenter,
    'row--spaced--1': spaced === true || spaced === 1,
    'row--spaced--2': typeof spaced === 'number' && spaced === 2,
  };

  return (
    <div className={classnames(classes, className)} {...props}>
      {children}
    </div>
  );
};

export default Row;
