// @flow
import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import classnames from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import './link.less';

type Props = {
  to: string,
  children: React.Node,
  className?: string,
  button?: boolean,
};

const Link = ({ children, button, to, className, ...props }: Props) => {
  const cls = classnames(className, { 'button': button });
  if (to.match(/^https?:\/\//)) {
    return (
      <OutboundLink href={to} className={cls} {...props}>
        {children}
      </OutboundLink>
    );
  } else {
    return (
      <GatsbyLink to={to} className={cls} {...props}>{children}</GatsbyLink>
    );
  }
};

export default Link;
