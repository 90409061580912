// @flow
import * as React from 'react';
import './input.less';

type Props = {
  className?: string,
  type?: 'text' | 'number' | 'password' | 'email',
  placeholder?: string,
};

const Input = ({ className, type, placeholder, ...props }: Props) => (
  <div className="input">
    <input type={type || 'text'} className="input__el" placeholder={placeholder} {...props}/>
  </div>
);

export default Input;
