// @flow
import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Link from '../components/link';
import iconSrc from '../assets/images/icon.svg';
import Container from '../components/grid/container';
import Row from '../components/grid/row';
import Column from '../components/grid/column';
import './navbar.less';

type Props = {|
  hideTitle?: boolean,
|};

export default ({ hideTitle }: Props) => (
  <StaticQuery query={graphql`
    query {
        site {
            siteMetadata {
                title
                menus {
                    main {
                        key
                        url
                        name
                    }
                }
            }
        }
    }
  `} render={({ site: { siteMetadata } }) => (
    <nav className="navbar">
      <Container>
        <Row>
          <Column className="navbar__container">
            <Link to="/" className="navbar__icon">
              <img src={iconSrc} alt="Insomnia REST Client"/>
            </Link>

            {!hideTitle && (
              <Link to="/" className="navbar__title">
                <h1>{siteMetadata.title}</h1>
              </Link>
            )}

            <ul className="navbar__items">
              {siteMetadata.menus.main.map(item => (
                <li key={item.key} data-menu-item={item.key}>
                  <Link to={item.url}>{item.name}</Link>
                </li>
              ))}
            </ul>
          </Column>
        </Row>
      </Container>
    </nav>
  )}/>
);
