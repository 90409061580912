// @flow
import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Container from '../components/grid/container';
import './footer.less';
import SubscribeForm from './subscribe-form';
import Row from '../components/grid/row';
import Column from '../components/grid/column';
import Link from '../components/link';

const Footer = () => (
  <StaticQuery query={graphql`
    query {
        site {
            siteMetadata {
                title
                menus {
                    footer {
                        key
                        url
                        name
                    }
                }
            }
        }
    }
  `} render={({ site: { siteMetadata } }) => (
    <footer className="footer">
      <Container>
        <Row vCenter spaced>
          <Column flex={4} center>
            <SubscribeForm fullWidth>
              Join Beta List
            </SubscribeForm>
          </Column>
          <Column flex={4} className="txt-center footer__menu">
            {siteMetadata.menus.footer.map(m => (
              <Link to={m.url} key={m.key}>
                {m.name}
              </Link>
            ))}
            <div className="faint-2 txt-sm marg-top">
              © {new Date().getFullYear()} Platform Pixels
            </div>
          </Column>
        </Row>
      </Container>
    </footer>
  )}/>
);

export default Footer;
