// @flow
import * as React from 'react';
import Link from './link';
import classnames from 'classnames';

import './button.less';

type Props = {
  children: React.Node,
  to?: string,
  className?: string,
};

const Button = ({ children, to, className, ...props }: Props) => {
  if (to) {
    return (
      <Link to={to} className={classnames(className, 'button')} {...props}>
        {children}
      </Link>
    );
  } else {
    return (
      <button className={classnames(className, 'button')} {...props}>
        {children}
      </button>
    );
  }
};

export default Button;
