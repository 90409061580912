// @flow
import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import classnames from 'classnames';
import Helmet from 'react-helmet';
import Title from '../components/title';
import Navbar from '../partials/navbar';
import Footer from '../partials/footer';
import twitterPromoSrc from '../assets/images/twitter-promo.png';
import './default.less';


type Props = {|
  slim?: boolean,
  children: React.Node,
  hideTitle?: boolean,
  pageTitle?: string,
  pageDescription?: string,
|};

type Query = {
  site: {
    siteMetadata: {
      title: string,
      twitter: string,
      description: string,
      deployTime: string,
      menus: Array<{
        key: string,
        url: string,
        name: string,
      }>,
    },
  },
};

class DefaultLayout extends React.Component<Props> {
  componentDidMount() {
    if (window && window.netlifyIdentity) {
      window.netlifyIdentity.on('init', user => {
        if (!user) {
          window.netlifyIdentity.on('login', () => {
            document.location.href = '/admin/';
          });
        }
      });
    }
  }

  render() {
    const { slim, children, hideTitle, pageTitle, pageDescription } = this.props;
    return (
      <StaticQuery query={graphql`
        query {
            site {
                siteMetadata {
                    title
                    twitter
                    description 
                    deployTime
                    menus {
                        main {
                            key
                            url
                            name
                        }
                    }
                }
            }
        }
      `} render={({ site: { siteMetadata } }: Query) => {
        return (
          <React.Fragment>
            <Title siteTitle={siteMetadata.title}/>
            <Helmet>
              {/* Metadata */}
              <meta name="description" content={pageDescription || siteMetadata.description}/>
              <meta name="deployed:time" content={siteMetadata.deployTime}/>

              {/* Twitter Card */}
              <meta name="twitter:card" content="summary_large_image"/>
              <meta name="twitter:site" content={siteMetadata.twitter}/>
              <meta name="twitter:title" content={pageTitle || siteMetadata.title}/>
              <meta name="twitter:description" content={pageDescription || siteMetadata.description}/>
              <meta name="twitter:image" content={'https://platformpixels.com' + twitterPromoSrc}/>

              <body className={classnames({ 'container--slim': slim })}/>
            </Helmet>
            <Navbar hideTitle={hideTitle}/>
            <main role="main">
              {children}
            </main>
            <Footer/>
          </React.Fragment>
        );
      }}/>
    );
  }
}

export default DefaultLayout;
