// @flow
import * as React from 'react';
import classnames from 'classnames';
import './column.less';

type Props = {
  children?: React.Node,
  flex?: number,
  vCenter?: boolean,
  center?: boolean,
  className?: string,
};

const Column = ({ children, className, vCenter, center, flex, ...props }: Props) => (
  <div className={classnames(`column column--${flex || 1}`, {
    'column--vcenter': vCenter,
    'column--center': center,
  }, className)} {...props}>
    {children}
  </div>
);

export default Column;
