// @flow
import * as React from 'react';
import classnames from 'classnames';

import './form.less';

type Props = {|
  children: React.Node,
  netlifyName?: string,
  method?: string,
  className?: string,
  rightAlign?: boolean,
  fullWidth?: boolean,
  action?: string,
  onSubmit?: (SyntheticEvent<HTMLFormElement>) => any
|};

const Form = ({ children, action, method, netlifyName, rightAlign, fullWidth, className, onSubmit, ...props }: Props) => (
  <form name={netlifyName}
        netlify-honeypot={netlifyName ? 'bot-field' : null}
        action={action}
        data-netlify={netlifyName ? 'true' : null}
        method={method}
        onSubmit={onSubmit}
        className={classnames('form', className, {
          'form--right-align': rightAlign,
          'form--full-width': fullWidth,
        })}
        {...props}>
    {/* Add form name for Netlify */}
    {netlifyName && (
      <React.Fragment>
        <div className="hide">
          <label>Don’t fill this out if you're human:
            <input name="bot-field"/>
          </label>
        </div>
        <input type="hidden" name="form-name" value={netlifyName}/>
      </React.Fragment>
    )}

    {children}
  </form>
);

export default Form;
