// @flow
import * as React from 'react';
import PostDate from '../components/date';

import './blog-post.less';
import { Helmet } from 'react-helmet';

type Props = {|
  title: string,
  children: React.Node,

  // Optional
  featureImgSrc?: string,
  timeToRead?: string,
  excerpt?: string,
  date?: Date,
|};

const BlogPost = ({ title, date, excerpt, timeToRead, children, featureImgSrc }: Props) => (
  <article className="blog-post">
    <Helmet>
      {featureImgSrc && (
        <meta name="twitter:image" content={'https://platformpixels.com' + featureImgSrc}/>
      )}
      {excerpt && timeToRead && (
        <meta name="twitter:description" content={`[${timeToRead} minute read] ${excerpt}`}/>
      )}
      <meta name="twitter:title" content={title}/>
    </Helmet>
    <header className="blog-post__header">
      <h1>{title}</h1>
      {date && (
        <div className="blog-post__header__meta">
          <PostDate date={new Date(date)}/>
        </div>
      )}
    </header>
    <div className="blog-post__content">
      {children}
    </div>
  </article>
);

export default BlogPost;
