// @flow
import * as React from 'react';
import Form from '../components/form';
import Input from '../components/input';
import Button from '../components/button';
import { trackEvent } from '../utility/analytcs';


type Props = {|
  rightAlign?: boolean,
  fullWidth?: boolean,
  children?: string,
|};

class SubscribeForm extends React.Component<Props> {
  didFocusName: boolean = false;
  didFocusEmail: boolean = false;

  onFocusName = () => {
    if (this.didFocusName) {
      return;
    }

    this.didFocusName = true;
    trackEvent('Subscribe Form', 'focus', 'name')
  };

  onFocusEmail = () => {
    if (this.didFocusEmail) {
      return;
    }

    this.didFocusEmail = true;
    trackEvent('Subscribe Form', 'focus', 'email')
  };

  render() {
    let { rightAlign, fullWidth, children } = this.props;
    return (
      <Form netlifyName="subscribe" rightAlign={rightAlign} fullWidth={fullWidth} action="/thank-you">
        <Input required placeholder="Jennifer Stanfield" name="name" onFocus={this.onFocusName}/>
        <Input required type="email" placeholder="email@example.com" name="email" onFocus={this.onFocusEmail}/>
        <Button type="submit">
          {children || 'Join The Beta!'}
        </Button>
      </Form>
    );
  }
}

export default SubscribeForm;
