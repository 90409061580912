// @flow
import * as React from 'react';
import classnames from 'classnames';
import './container.less';
import Column from './column';
import Row from './row';

type Props = {
  withColumn?: boolean,
  children: React.Node,
  className?: string,
};

const Container = ({ withColumn, children, className, ...props }: Props) => (
  <div className={classnames('container', className)} {...props}>
    {withColumn
      ? <Row><Column>{children}</Column></Row>
      : children
    }
  </div>
);

export default Container;
